/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EdgeDetectUtils } from '../../../utils/EdgeDetectUtils';
import ObjectUtils from '../../../utils/ObjectUtils';
import { Option } from '../../Option';
import Checkbox from '../form-control/Checkbox';
import { Input, InputStyle } from '../form-control/Input';
import RadioButton from '../form-control/RadioButton';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import XIcon from '../icon/XIcon';
import WordTag, { WordTagStyle } from './WordTag';
import withSlots, { SlotDefinitions } from '../../../wrappers/withSlot';
import useSlot from '../../../hooks/useSlots';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';

export enum DueDateFilter {
  Today,
  Week,
  Overdue,
}

export enum FilterSelectMode {
  Multi,
  Single,
  SingleDefault,
}

type FilterTagProps = {
  tag?: Option<string, string>;
  options: Option<string | number, boolean>[];
  mode?: FilterSelectMode;
  removeFilter?: () => void;
  onFiltersChange: (filters: Option<any, boolean>[]) => void;
  onSearch?: (searchTerm: string) => void;
  onOpen?: () => void;
};

const FilterTag = withSlots<FilterTagProps, SlotDefinitions<['Footer']>>((props) => {
  const {
    tag = {
      id: '',
      text: '',
      value: '',
    },
    removeFilter,
    onFiltersChange,
    onSearch,
    onOpen,
    options,
    mode = FilterSelectMode.Single,
  } = props;
  const { t } = useTranslation('common');
  const [menuOpen, setMenuOpen] = useState(false);
  const [horizontalPos, setHorizontalPos] = useState('left-0');
  const [verticalPos, setVerticalPos] = useState('top-6');
  const menuRef = useRef<HTMLDivElement>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [wordTag, setWordTag] = useState<Option<string, string>>(tag);

  const FooterTemplate = useSlot(props, 'Footer');

  // #region Menu Layout

  useEffect(() => {
    const mouseDownListener = (e: MouseEvent): void => {
      if (
        menuOpen &&
        menuRef.current &&
        !(menuRef.current.contains(e.target as Node) || menuRef.current === e.target) &&
        menuContainerRef.current &&
        !(menuContainerRef.current.contains(e.target as Node) || menuContainerRef.current === e.target)
      ) {
        setMenuOpen((prev) => !prev);
      }
    };

    document.addEventListener('mousedown', mouseDownListener);

    return () => {
      document.removeEventListener('mousedown', mouseDownListener);
    };
  }, [menuOpen, onOpen]);

  useLayoutEffect(() => {
    if (null !== menuRef.current) {
      const dropdownEdges = EdgeDetectUtils.checkEdges(menuRef.current);
      if (dropdownEdges.any) {
        // if any hidden edges are detected
        if (dropdownEdges.left) {
          setHorizontalPos('left-0');
        } else if (dropdownEdges.right) {
          setHorizontalPos('right-0');
        }
        if (dropdownEdges.bottom) {
          setVerticalPos('bottom-full');
        } else if (dropdownEdges.top) {
          setVerticalPos('top-auto');
        }
      }
    }
  }, [menuOpen]);

  // #endregion

  // #region Filters change

  const onRadioButtonChanged = (index: number, value: boolean): void => {
    let newOptions = ObjectUtils.DeepClone(options);

    if (mode === FilterSelectMode.Single || mode === FilterSelectMode.SingleDefault) {
      newOptions = newOptions.map((option) => ({ ...option, value: false }));
      newOptions[index].value = true;
    } else {
      newOptions[index].value = value;
    }
    onFiltersChange(newOptions);
  };

  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchPhrase = event.target.value;
    setSearchTerm(searchPhrase);
    onSearch && onSearch(searchPhrase);
  };

  const toggleSelectAll = (value: boolean) => {
    let newOptions = ObjectUtils.DeepClone(options);
    newOptions = newOptions.map((option) => ({ ...option, value }));
    onFiltersChange(newOptions);
  };

  // #endregion

  const appliedFiltersCount = useMemo(() => {
    return options.filter((option) => option.value).length;
  }, [options]);

  const disabledState = useMemo(() => {
    return appliedFiltersCount === 0;
  }, [appliedFiltersCount]);

  useEffect(() => {
    if (appliedFiltersCount === 1) {
      setWordTag((prev) => ({ ...prev, text: options.find((option) => option.value)?.text || prev.text }));
    } else {
      setWordTag((prev) => ({ ...prev, text: tag.text }));
    }
  }, [appliedFiltersCount, options, tag.text]);

  return (
    <div>
      {tag.text && (
        <span className="text-color-3 text-dpm-12" id={tag.text.replaceAll(' ', '-')}>
          {tag.text}
        </span>
      )}
      <div
        ref={menuContainerRef}
        {...mouseAndKeyboardCallbackProps((e) => {
          onOpen && !menuOpen && onOpen();
          if (searchRef.current ? searchRef.current !== e.target : true) {
            setMenuOpen((prev) => !prev);
          }
        })}
        className="cursor-pointer"
        data-cy={`filter-tag-${wordTag.id}`}
        aria-labelledby={tag.text ? tag.text.replaceAll(' ', '-') : undefined}
        tabIndex={0}
      >
        <WordTag tag={wordTag} disabled={disabledState} style={WordTagStyle.STROKE}>
          {appliedFiltersCount > 1 && mode === FilterSelectMode.Multi && (
            <div className="text-primary-1 text-dpm-12 ml-2 flex h-5 w-5 items-center justify-center rounded-full bg-white text-center font-medium">
              {appliedFiltersCount}
            </div>
          )}
          <ChevronIcon
            type={menuOpen ? ChevronType.UP : ChevronType.DOWN}
            className={`${disabledState ? 'text-primary-1' : 'text-white'} ml-2 h-4 w-4`}
          />
          <div
            ref={menuRef}
            role="menu"
            aria-orientation="vertical"
            className={`min-w-dropdown-box absolute w-72 cursor-default ${horizontalPos} ${verticalPos} border-primary-1 rounded-dpm-sm z-50 mt-4 border-2 bg-white px-2 py-2 shadow-xl ${
              menuOpen ? '' : 'hidden'
            }`}
            data-cy="filter-menu"
          >
            {onSearch && (
              <Input
                innerRef={searchRef}
                style={InputStyle.MINIMAL}
                value={searchTerm}
                onChange={onSearchTextChange}
                placeholder={t('list.filter.search')}
              />
            )}
            <div className="p-1">
              <div className="text-dpm-14 flex flex-row justify-between font-medium">
                {mode === FilterSelectMode.Multi && (
                  <span
                    className="cursor-pointer hover:underline"
                    {...mouseAndKeyboardCallbackProps(() => toggleSelectAll(true))}
                    data-cy="select-all"
                  >
                    {t('list.filter.select-all')}
                  </span>
                )}
                {mode !== FilterSelectMode.SingleDefault && (
                  <span
                    className="cursor-pointer hover:underline"
                    {...mouseAndKeyboardCallbackProps(() => toggleSelectAll(false))}
                    data-cy="clear-all"
                  >
                    {t('list.filter.clear-all')}
                  </span>
                )}
              </div>

              <div className="max-h-60 max-w-full overflow-y-auto">
                {(mode === FilterSelectMode.Single || mode === FilterSelectMode.SingleDefault) &&
                  options.map((option, i) => {
                    if (option.disabled && option.hasDivider) {
                      return (
                        <div key={option.id} className="border-gray-5 mt-4 border-b font-light">
                          {option.text}
                        </div>
                      );
                    }

                    return (
                      <RadioButton
                        key={option.id}
                        value={option.value}
                        label={option.text}
                        multi={false}
                        onChange={(value) => onRadioButtonChanged(i, value)}
                        role="menuitem"
                      />
                    );
                  })}
                {mode === FilterSelectMode.Multi &&
                  options.map((option, i) => {
                    if (option.disabled && option.hasDivider) {
                      return (
                        <div key={option.id} role="menuitem" className="border-gray-5 mt-3 border-b font-light">
                          {option.text}
                        </div>
                      );
                    }

                    return (
                      <Checkbox
                        key={option.id}
                        value={option.value}
                        disabled={option.disabled}
                        label={option.text}
                        onChange={(value) => onRadioButtonChanged(i, value)}
                        role="menuitem"
                      />
                    );
                  })}
              </div>
              {FooterTemplate() && (
                <div className="border-t pt-2">
                  <FooterTemplate />
                </div>
              )}
            </div>
          </div>
          {removeFilter && (
            <XIcon className={`${disabledState ? 'text-color-3' : 'text-white'} h-4 w-4`} onClick={removeFilter} data-cy="remove-filter" />
          )}
        </WordTag>
      </div>
    </div>
  );
});

export default FilterTag;
